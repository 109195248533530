<template>
    <div class="index-product-container">
        <div class="index-product-caption" >
            <div class="index-product-caption-title" @click="redirect">{{title}}</div>
            <div class="index-product-caption-text">{{text}}</div>
            <div class="index-product-caption-button">
                <va-button class="index-product-button" :href="title == 'px40' ? 'https://px40.com.br/' : 'https://flexxs.com.br/'" target="_blank" :rounded="false" color="#fd712160" text-color="laranja" border-color="laranja">Experimente</va-button>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'IndexProductCaption',
    props:{
        title: String, 
        text: String,
    },
    components: { },
    methods: {
        redirect() {
            this.title == 'px40' ? window.open('https://px40.com.br/', '_blank') : window.open('https://flexxs.com.br/', '_blank') 
        }
    }
}
</script>

<style scoped>
.index-product-container{   
    display: flex;
    justify-content: center;
}
.index-product-caption{
    display: flex;
    flex-direction: column;
    align-items: start;
    width: 90%;
    background-color: var(--c6);
    border-radius: 10px;
    padding-left: 2vw; 
    padding-right: 2vw;
}

.index-product-caption-title{
    font-size: 3em;
    padding: 1rem;
    text-align: left;
    color: var(--preto);
}
.index-product-caption-title:hover{
    cursor: pointer;
}
.index-product-caption-text{
    padding: 1rem;
    font-size: 1.2rem;
    line-height: 1.3;
    text-align: left;
    color: var(--preto);
}
.index-product-caption-button {
    width: 100%;
    padding: 1rem;
    /* background-color:orange; */
}
.index-product-button {
    width: 40%;
    border-radius: 20px;
}

</style>