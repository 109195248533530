<template>
    <div class="index-principal-quadro">
        <div class="index-content-quadro">
            <img class="index-principal-img" :src="require('@/assets/Crop_logo_allstech.svg')" alt="" >
            <small class="index-principal-caption">Tecnologia e Inovação para<br> todos os negócios</small>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'IndexSlideshow',
    }
</script>

<style scoped>

.index-principal-quadro{
    padding-top: 80px;
    flex-direction: column;
    display: flex;
    height: 150vh;
    width: 100%;
    padding-left: 4vw;
    background-image:  linear-gradient(rgba(89, 92, 102, 0.199) 37%, rgba(254, 255, 250, 0.171) 80%),  url('../../assets/fundo-grid-allsTech.svg');
    background-size: cover;
    background-position: center; 
    background-repeat: no-repeat;
}

.index-content-quadro {
    padding-top: 3rem;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
}

.index-principal-caption{
    font-size: 2.5rem;
    padding-left: 2vw;
    font-family: 'Roboto';
    color: var(--cinza);
}

.index-principal-img{
    width: 35%;
}

@media only screen and (max-width: 600px) {
    .index-principal-quadro{
        height: 100%;
        background-position-x: right;
    }
    .index-principal-img {
        width: 80%;
    }
    .index-content-quadro {
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
    }
    .index-principal-caption {
        font-size: 1.7rem;
        width: 90%;
    }
   
}

</style>