<template>
	<div class="geral">
		<div class="contatos">
			<va-inner-loading icon="autorenew" class="contatos-inputs" :loading='loading'>
				<!-- <div class="contatos-caption">Fale conosco</div>
				<div class="contatos-caption" style="font-size: 1.3em">Preencha o formulário e envie sua mensagem.</div> -->
				<va-input class="contatos-content-inputs" color="laranja" type="text" label="NOME" style="padding: 10px;" v-model="body.NOME" placeholder="Digite seu nome" ></va-input>
				<va-input class="contatos-content-inputs" color="laranja" type="email" label="EMAIL" style="padding: 10px;" v-model="body.EMAIL" placeholder="Digite seu email" ></va-input>
				<va-input class="contatos-content-inputs" color="laranja" type="phone" label="TELEFONE" style="padding: 10px;" v-model="body.TELEFONE" placeholder="Digite seu telefone"></va-input>
				<va-input class="contatos-content-inputs" color="laranja" type="textarea"  label="MENSAGEM" :min-rows="3" :max-rows="5" style="padding: 10px;"  v-model="body.MENSAGEM" placeholder="Digite sua mensagem"/>
				<va-button text-color="branco" :rounded="false" color="verde" class="contatos-button" @click="enviar">Enviar</va-button>
			</va-inner-loading>
		</div>
	</div>
</template>

<script>
export default {
	name: 'Contatos',
	data: () => ({
        body: {},
		loading: false
    }),
	methods: {
		async enviar(){
			this.loading=  true;
			await this.axios.post('https://flexxs.com.br:5002/api/faleconosco',{
				...this.body,
				LOCAL: 'Allstech'
			});
			this.body.NOME = null;
			this.body.EMAIL = null;
			this.body.TELEFONE = null;
			this.body.MENSAGEM = null;
			this.loading =  false;
        }
    }
}
</script>

<style scoped>

.geral{
    width: 100%;
	height: 100%;
}

.contatos{
    width: 100%;
    height: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}
.contatos-inputs{
	width: 75%;
	padding-top: 5vh;
	background-color: var(--c7);
	border-radius: 15px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	box-shadow: 0px 4px 10px 0.3px rgba(0, 0, 0, 0.25);
}
.contatos-content-inputs {
	width: 90%;
}
.contatos-caption{
	width: 100%;
	padding: 10px;
	font-size: 2em;
	color: var(--preto);
}

.contatos-button{
	width: 30%;
	margin-block: 20px;
	transition: all 1s ease-in-out;
}
.contatos-button:hover {
	transform: scale(1.1);
}


@media only screen and (max-width: 1000px) {
	.contatos-inputs {
		padding-top: 50px;
		width: 90%;
	}
	.geral {
		padding-bottom: 20px;
	}

}

</style>