<template>
<div class="index-product-container">
    <img :src="require('@/assets/' + name)" alt="" class="index-product-img">
</div>
</template>

<script>
export default {
    name: 'IndexProductImage',
    props:{
        name: String,
    },
    components: { },
}
</script>

<style scoped>
.index-product-img{
    width: 70%;
    height: 300px;
    text-align: center;
}

.index-product-container{   
    display: flex;
    justify-content: center;
}


@media only screen and (max-width: 600px) {
    .index-product-container {
        flex-direction: column;
        align-items: center;
    }
    .index-product-img {
        width: 90%;
    }
}
</style>