<template>
    <div class="index-products">
        <div class="index-products-title">EXPLORE<h1 class="index-product-subtitle">PRODUTOS</h1></div>
        <div class="index-products-quadros">
            <IndexProductImage name= "compilado-px40.svg"/>
            <IndexProductCaption title="px40" text="O PX40 é uma solução em software que transforma a gestão de ativos e recursos, simplificando e otimizando o controle de recursos de forma intuitiva e eficaz. Com ele, você pode, além de muitas outras funções, abrir chamados, reportar problemas, gerenciar ordens de serviço e ter uma visão completa e transparente de seus ativos e da sua operação. Acesse a página do produto para explorar as possibilidades que o PX40 oferece e revolucione a gestão da sua empresa agora mesmo." />
        </div>
        <div class="index-products-quadros">
            <IndexProductCaption title="flexxs iT" text="O flexxs iT é uma solução completa em hardware e software para aquisição, processamento e controle de sinais e dados da operação. Com recursos como telecomando, telemetria de energia e sensoriamento avançado, ele transforma dados brutos em insights valiosos para o seu negócio. Acesse nossa página para conhecer nossas soluções de monitoramento inteligente." />
            <IndexProductImage  name= "compilado-flexxs.svg"/>
        </div>
    </div>
</template>

<script>
import IndexProductImage from "@/components/Index/IndexProductImage"
import IndexProductCaption from "@/components/Index/IndexProductCaption"
export default {
    name: 'IndexProducts', 
    components: {IndexProductImage, IndexProductCaption},
}
</script>

<style scoped>

.index-products{
    background-color: var(--branco);
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 2.5vw;
    padding-top: 120px;

}
.index-products-quadros{
    display: grid;
    grid-template-columns: 50% 50%;
    margin-bottom: 15vh;
}

.index-products-title{
    z-index: 1;
    width: 100%;
    position: relative;
    height: auto;
    font-style: normal;
    font-weight: 800;
    font-size: 8rem;
    line-height: 150px;
    color: var(--c7);
    text-align: center;
    margin-bottom: 2rem;
}
.index-product-subtitle {
    font-size: 3rem;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: var(--laranja);
    font-style: normal;
}

@media only screen and (max-width: 600px) {
    .index-products {
        padding-top: 50px;
    }
    .index-products-title {
        font-size: 3.9rem;
    }
    .index-product-subtitle {
        font-size: 2.4rem;
    }
    .index-products-quadros {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        grid-auto-columns: 100%;
        margin-bottom: 8vh;
    }
}

</style>