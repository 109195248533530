import { createApp } from 'vue'
import App from './App.vue'
import router from './configs/router.js'
import axios from 'axios'
import VueAxios from 'vue-axios'
import FontAwesomeIcon from '@/configs/font-awesome'
import { vfmPlugin } from 'vue-final-modal'
import { VuesticPlugin } from 'vuestic-ui'
import 'vuestic-ui/dist/vuestic-ui.css'
import OpenLayersMap from 'vue3-openlayers'
import 'vue3-openlayers/dist/vue3-openlayers.css'
import 'v-calendar/dist/style.css';
import VCalendar from 'v-calendar';
import VueSignaturePad from 'vue-signature-pad';
import 'vue3-tour/dist/vue3-tour.css'
import Vue3Tour from 'vue3-tour'


//require('vue-tour/dist/vue-tour.css')
axios.defaults.headers.post['Content-Type'] = 'application/json';


createApp(App)
    .component("fa", FontAwesomeIcon)
    .use(router)
    .use(VuesticPlugin,{
        colors: {
            verde: '#3B787B', 
            laranja: '#FD5E05',
            laranja_flexxs : '#F85B40',
            verde_px40 : '#3EDE93',
            cinza: '#32353E',
            branco: '#FEFFFA',
            azul: '#3CB3DE',
            c3: '#CCCCCC',
            c1: '#3C3F4A',
            c5: '#636363',
        }
    })

    .use(vfmPlugin)
    .use(VueSignaturePad)
    .use(VueAxios, axios)
    .use(OpenLayersMap)
    .use(VCalendar, {})
    .use(Vue3Tour)
    .mount('#app')
    