<template>
    <div class="index-contact">
        <div class="index-contact-informations">
            <div class="index-footer-title">
                <h1 class="index-contact-title">CONTATE-NOS</h1>
                <ul class="index-footer-title-sub">
                    <li class="contact-itens"><fa class="icons-contact" :icon="['fas', 'envelope']"/> engenharia@allstech.com.br</li>
                    <li class="contact-itens"><a class="index-whatsapp" href="https://wa.me/5531990775620" target="_blank" ><fa class="icons-contact" :icon="['fab', 'whatsapp']"/> +55 31 99077-5620</a>
                    </li>
                </ul>
            </div> 
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d264.71119046032675!2d-44.88391229579869!3d-20.127895058504514!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xa0af00faf987fb%3A0xa7a28d677342e48b!2sAllstech!5e0!3m2!1spt-BR!2sbr!4v1686063211334!5m2!1spt-BR!2sbr" width="80%" height="65%" style="border:none;" allowfullscreen="" loading="lazy" class="iframe" referrerpolicy="no-referrer-when-downgrade"></iframe>
        </div>
        <div> 
            <Contatos />
        </div>
    </div>
</template>

<script>
import Contatos from './Contatos.vue';

export default {
    name: 'IndexContact',
    components: {Contatos}
}
</script>

<style scoped>
.index-contact {
    width: 100%;
    height: 100vh;
    display: grid;
    grid-template-columns: 50% 50%;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: start;
    background-image: url('../../assets/fundo-contact.jpg');
    padding-top: 120px;
}
.index-contact-informations {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    padding: 2.5vw
}
.index-contact-title {
    font-size: 2.5rem;
    font-family: 'Roboto';
    color: var(--laranja);
    padding-bottom: 1rem;
}
.contact-itens {
    display: flex;
    color: var(--branco);
    font-family: 'Roboto';
    font-size: 1.2em;
    margin-bottom: 5px;
}

.index-whatsapp{
    color: var(--branco);
}

.icons-contact{
    color: var(--branco);
   font-size: 1.3rem;
   padding-right: 5px;
}
@media only screen and (max-width: 1000px) {
    .index-contact {
        grid-template-columns: 100%;
        display: flex;
        justify-content: space-evenly;
        flex-direction: column;
        height: 100%;
        width: 100%;
        padding-top: 50px;
    }
    .index-contact-informations {
        justify-content: center;
        align-items: center;
    }
    .iframe {
        display: none;
    }
}


</style>