<template>
    <div class="index-footer">
        <div class="social-media">
            <a href="https://linktr.ee/allstech" target="_blank" ><img class="logoAllsTech" src="../../assets/logo.png" alt=""></a>
            <a href="https://www.instagram.com/alls_tech" target="_blank"><fa class="icons-footer" :icon="['fab', 'instagram']" color='var(--branco)'/></a>
            <a href="https://www.youtube.com/@allstech" target="_blank"><fa class="icons-footer" :icon="['fab', 'youtube']" color='var(--branco)'/></a>
        </div>
        <div class="copyright">© 2024 Alls Tech | Todos direitos reservados </div>
    </div>
    
    
</template>

<script>
export default {
    name: 'IndexFooter',
    components: { },
}
</script>

<style scoped>
.index-footer{
    width: 100%;
    background-color: var(--preto);
    display: grid;
    padding: 1em;
    align-items: center;
    grid-template-columns: 50% 1fr;
}

.logoAllsTech{
   width: 70px;
   margin-right: 20px;
}

.copyright {
    color: var(--branco);
    text-align: right;
}
.social-media {
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
}

.icons-footer {
    margin-right: 10px;
    font-size: 1.8rem;
    color: var(--branco);
}



</style>