import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";

import { faTablet } from "@fortawesome/free-solid-svg-icons";
library.add(faTablet);

import { faHouseUser } from "@fortawesome/free-solid-svg-icons";
library.add(faHouseUser);

import { faBell } from "@fortawesome/free-solid-svg-icons";
library.add(faBell);

import { faWindowClose } from "@fortawesome/free-solid-svg-icons";
library.add(faWindowClose);

import { faAngleDown } from "@fortawesome/free-solid-svg-icons";
library.add(faAngleDown);

import { faAngleUp } from "@fortawesome/free-solid-svg-icons";
library.add(faAngleUp);

import { faPen } from "@fortawesome/free-solid-svg-icons";
library.add(faPen);

import { faChartLine } from "@fortawesome/free-solid-svg-icons";
library.add(faChartLine);

import { faIndustry } from "@fortawesome/free-solid-svg-icons";
library.add(faIndustry);

import { faLock } from "@fortawesome/free-solid-svg-icons";
library.add(faLock);

import { faDownload } from "@fortawesome/free-solid-svg-icons";
library.add(faDownload);

import { faPlus } from "@fortawesome/free-solid-svg-icons";
library.add(faPlus);

import { faClipboardCheck } from "@fortawesome/free-solid-svg-icons";
library.add(faClipboardCheck);

import { faFolderOpen } from "@fortawesome/free-solid-svg-icons";
library.add(faFolderOpen);

import { faFolder } from "@fortawesome/free-solid-svg-icons";
library.add(faFolder);

import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
library.add( faWhatsapp );

import { faInstagram } from "@fortawesome/free-brands-svg-icons";
library.add( faInstagram );

import { faYoutube } from "@fortawesome/free-brands-svg-icons";
library.add( faYoutube);

import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
library.add( faEnvelope);

export default FontAwesomeIcon;