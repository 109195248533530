<template>
    <div class="index-title-button" v-bind:class="{ 'title_scroll_button': isScrolled }">
        {{title}}
    </div>
</template>

<script>
export default {
    name: 'IndexTitleButton',
    props:{
        title: String
    },
    data() {
      return {
        isScrolled: false
      };
    },
    mounted() {
        window.addEventListener('scroll', this.checkScroll);
    },
    methods: {
        checkScroll() {
            if(window.scrollY > 70) {
                this.isScrolled = true;
            } else {
                this.isScrolled = false;
            }
            
        },
    }
}
</script>

<style scoped>
.index-title-button{
    padding: 15px;
    margin: 0 10px;
    font-size: 1.5rem;
    border-radius: 7px;
    color: var(--branco);
    text-shadow: 0px 2px 2px rgba(0, 0, 0, 0.664);
    transition: all 1s ease-in-out;
}
.title_scroll_button {
    color: var(--preto);
    text-shadow: none;
}
.index-title-button:hover{
    cursor: pointer;
    background-color: rgba(0, 0, 0, 0.1);
}


@media only screen and (max-width: 700px) {
    .index-title-button{
        width: 70vw;
        align-content: center;
        color: var(--cinza);
        text-shadow: none;
    }
}
</style>