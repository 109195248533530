import { createWebHistory, createRouter } from "vue-router";
import Index from '@/views/Index'

const routes = [
    {
        name: 'Index',
        path: '/',
        component: Index
    },
];

const router = createRouter({
  mode: 'history',
  history: createWebHistory(),
  routes,
});

export default router;
