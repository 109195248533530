<template>
    <FloatButton @click="openWhatsapp" :icon="['fab', 'whatsapp']" type="fa" />
<div class="index">
    <div class="topbar-slideshow">
        <IndexTopbar/>
        <IndexPrincipal />
    </div>
    <div class="index-content">
        <IndexAbout />
        <IndexSolutions />
        <IndexProducts />
        <IndexContact />
        <IndexFooter />
    </div>
</div>
</template>

<script>
import FloatButton from '@/utils/FloatButton';
import IndexPrincipal from '@/components/Index/IndexPrincipal'
import IndexProducts from '@/components/Index/IndexProducts'
import IndexFooter from '@/components/Index/IndexFooter'
import IndexTopbar from '@/components/Index/IndexTopbar'
import IndexAbout from '@/components/Index/IndexAbout'
import IndexSolutions from '@/components/Index/IndexSolutions'
import IndexContact from '@/components/Index/IndexContact.vue'

export default {
    name: 'Index',
    components: {IndexPrincipal, IndexProducts, IndexFooter, IndexTopbar, IndexAbout, IndexSolutions, IndexContact, FloatButton},
    methods: {
        openWhatsapp(){
            window.open('https://wa.me/5531990775620', '_blank')
        }
    }
}
</script>

<style scoped>
.index{
    width: 100%;
    height: 100%;
}
.topbar-slideshow{
    width: 100%;
    height: 100vh;
    display: flex;
}
.index-content {
    height: 100%;
    width: 100%;
}

@media only screen and (max-width: 600px) {
    .index-content {
        height: 100%;
    }
    .index {
        height: 100%;
    }
}
</style>