<template>
    <div class="index-principal">
        <IndexSlideshow/>
    </div>
</template>

<script>
import IndexSlideshow from "@/components/Index/IndexSlideshow"
export default {
    name: 'IndexPrincipal',
    components: {IndexSlideshow},
}
</script>

<style scoped>

.index-principal{
    width: 100vw;
    height: calc(100% - 70px);
} 

@media only screen and (max-width: 600px) {
    .index-principal{
        height: 100vh;
    } 
}

</style>