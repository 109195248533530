<template>
    <div class="index-about">
        <div class="index-about-text">
            <div class="index-about-title">VISÃO GERAL <h1 class="index-about-title-subtitle">SOBRE NÓS</h1></div>
            <div class="index-about-subtitle">Preparado para o futuro?</div>
            <div class="index-about-quadros">
                Fundada com a visão de revolucionar os processos de negócios através da tecnologia, nos destacamos na criação de ferramentas de gestão e monitoramento de ativos. Nossas soluções aprimoram a eficiência e produtividade dos negócios, capacitando-os a enfrentar os desafios de um ambiente industrial competitivo.<br><br>
                Acreditamos na tecnologia como catalisadora da mudança e na inovação como motor do crescimento. Estamos comprometidos em ajudar as empresas a navegar na era digital, transformando seus processos e alcançando novos patamares de sucesso.<br><br>
                Junte-se a nós na Allstech para um futuro promissor na indústria.
            </div>
        </div>
        <div class="index-about-image">
            <img class="enviroment-image" src="@/assets/sala14.png"/>
        </div>
    </div>
</template>

<script>
export default {
    name: 'IndexAbout', 
}
</script>

<style scoped>

.index-about{
    background-color: var(--branco);
    width: 100%;
    height: 90%;
    display: flex;
    flex-direction: row;
    padding-top: 120px;
}

.index-about-text{
    width: 60%;
}
.index-about-image{
    width: 40%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.enviroment-image {
    width: 80%;
    border-radius: 10px;
    box-shadow: 0px 5px 12px -2px;
}
.index-about-title{
    z-index: 1;
    width: 100%;
    position: relative;
    font-weight: 800;
    font-size: 8rem;
    line-height: 150px;
    color: var(--c7);
    text-align: left;
}
.index-about-title-subtitle {
    font-size: 3rem;
    position: absolute;
    padding-left: 2.5vw;
    top: 50%;
    transform: translate(0%, -50%);
    color: var(--laranja);
    font-style: normal;
    
}

.index-about-subtitle{
    width: 100%;
    height: auto;
    font-size: 2rem;
    text-align: left;
    color: var(--preto);
    padding-left: 2.5vw;
    padding-bottom: 1em;
}
.index-about-quadros {
    padding-left: 2.5vw;
    padding-right: 2.5vw;
    line-height: 1.3;
    font-size: 1.3rem;
}

@media only screen and (max-width: 600px) {
  .index-about{
    height: 100%;
    padding-top: 50px;
    flex-direction: column;
  }
  .index-about-text{
    width: 100%;
    height: 100%;
  }
  .index-about-title {
    font-size: 3.6rem;
    line-height: 150px;
    display: flex;
    text-align: center;
    justify-content: center;
  }
  .index-about-title-subtitle {
    font-size: 2.4rem;
  }
  .index-about-subtitle {
    font-size: 1.7rem;
  }
  .index-about-quadros {
    text-align: justify;
    font-size: 1.2rem;
    height: 100%;
  }
  .index-about-image {
    display: none;
  }
}

</style>