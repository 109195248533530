<template>
	<router-view class="app-view"></router-view>
</template>

<script>
export default {
	name: 'App',
    data () {
		return {
		}
    },
}
</script>

<style>
body, html {
	margin: 0;
	padding: 0;
	height: 100vh;
	font-family: 'poppins', sans-serif;
}
.app-view {
	font-family: 'Roboto';
	margin: 0;
	height: 100vh;
	width: 100vw;
}
.app-loading{
	margin: 0;
	height: 100vh;
	width: 100vw;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	background-color: #32353E;
}
.app-loading img {
	width:7rem;
}
:root {
	--c1: #666666; 
	--cinza: #32353E; 
	--preto: #313131;
	--c3: #CCCCCC;
	--c4: #525766;
	--c5: #636363;
	--c6: #e2e1e181;
	--c7: #ccccccc8;
	--verde: #3B787B; 
	--azul: #3CB3DE;
	--verde_hover: #3CDE93ff;
	--laranja: #FD5E05;
	--vermelho: #f53d3d;
	--rosa: #ff6161;
	--roxo: #b86ff7;
	--amarelo: #FFDDAA;
	--branco: #FEFFFA;

}

::-webkit-scrollbar {
	width: 7px !important; 
	background-color: #1b1b1b;
	position: absolute !important;
}

::-webkit-scrollbar:hover {
 	width: 10px !important; 
}

::-webkit-scrollbar-button {
	height: 0;
}

::-webkit-scrollbar-thumb {
  background-color:#959595; 
  border-radius: 20px;
}

::-webkit-scrollbar-track {
	background-color: #1b1b1b; 
	border-radius: 20px;
}
.va-dropdown__content {
	background-color: var(--branco) !important;
	margin-top: 15px !important;
	width: 100vw;
}

.versao-web{
	display: block;
}

.versao-mobile img{
	width: 50%;
	margin: 50px 0;
}



@media only screen and (max-width: 100px) {
    .versao-mobile{
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		align-items: center;
		height: 100vh;
		color: white;
		background-color: #32353E;
	}
	.versao-web{
		display: none;
	}
}

</style>