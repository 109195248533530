<template>
    <div class="float-button">
     <slot name="content">
         <fa      v-if="icon != undefined && (type == 'fa'||type == undefined)" class="fa-whatsapp" :icon="icon"/>
         <va-icon v-if="icon != undefined && type == 'vuestic'" :name="icon"/>
     </slot>
 </div>
</template>

<script>
export default {
 name: 'FloatButton',
 props: ["icon", "type"]
}
</script>

<style scoped>
.float-button{
 position: fixed;
 bottom: 80px;
 right: 30px;
 /* padding: 5px; */
 border-radius: 100px;
 height: 50px;
   aspect-ratio: 1 / 1;
 display: flex;
 align-items: center;
 justify-content: center;
 color: var(--branco);
 z-index: 1000;
 box-shadow: 0 0 20px rgba(0,0,0,0.5);
 font-size: 60px;
}
.float-button:hover{
 cursor: pointer;
 /* box-shadow: 0 0 20px rgba(255,255,255,0.5); */
}

.fa-whatsapp  {
  color:#fff;
  background:
   linear-gradient(#25d366,#25d366) 14% 84%/16% 16% no-repeat,
   radial-gradient(#25d366 60%,transparent 0);
}
</style>