<template>
    <div class="index-title" v-bind:class="{ 'title_scroll': isScrolled }">
      <img  v-if="isScrolled == true" class="logo" src="@/assets/logo_allstech.svg" v-on:click="scrollHome" />
      <va-button-dropdown  color="#66000000" label="secondary" hide-icon v-if="showMenu && isScrolled">
        <template #label>
            <va-icon name="menu" size="large" color="cinza" class="mr-1"/>
        </template>
        <div class="index-title-buttons">
          <IndexTopbarButton class="index-title-buttons-solucoes" title="Sobre nós" v-on:click="scrollAbout" />
          <IndexTopbarButton class="index-title-buttons-solucoes" title="Soluções" v-on:click="scrollSolutions" />
          <IndexTopbarButton class="index-title-buttons-solucoes" title="Produtos" v-on:click="scrollProducts" />
          <IndexTopbarButton class="index-title-buttons-solucoes" id="contact" title="Contate-nos" v-on:click="scrollContact" />
        </div>
    </va-button-dropdown>
        <div v-if="this.showMenu == false" class="index-title-buttons">
          <IndexTopbarButton class="index-title-buttons-solucoes" title="Sobre" v-on:click="scrollAbout" />
          <IndexTopbarButton class="index-title-buttons-solucoes" title="Soluções" v-on:click="scrollSolutions" />
          <IndexTopbarButton class="index-title-buttons-solucoes" title="Produtos" v-on:click="scrollProducts" />
          <IndexTopbarButton class="index-title-buttons-solucoes" id="contact" title="Contate-nos" v-on:click="scrollContact" />
        </div>
    </div>
</template>
  
<script>
  import IndexTopbarButton from '@/components/Index/IndexTopbarButton'
  
  export default {
    name: 'IndexTitle',
    components: { IndexTopbarButton },
    data() {
      return {
        isScrolled: false,
        showMenu: false
      };
    },
    mounted() {
        window.addEventListener('scroll', this.checkScroll);
        window.addEventListener('resize', this.verificarTamanhoTela);
		this.verificarTamanhoTela();
    },
    unmounted() {
		window.removeEventListener('resize', this.verificarTamanhoTela);
	},
    methods: {
        verificarTamanhoTela() {
            this.showMenu = window.innerWidth <= 700;
        },
        checkScroll() {
            if(window.scrollY > 70) {
                this.isScrolled = true;
            } else {
                this.isScrolled = false;
            }
            
        },
        scrollHome(){
            document.querySelector('.index-principal').scrollIntoView({behavior: 'smooth'});
        },
        scrollSolutions(){
            document.querySelector('.index-solutions').scrollIntoView({behavior: 'smooth'});
        },
        scrollContact(){
            document.querySelector('.index-contact').scrollIntoView({behavior: 'smooth'});
        },
        scrollProducts(){
            document.querySelector('.index-products').scrollIntoView({behavior: 'smooth'});
        },
        scrollAbout(){
            document.querySelector('.index-about').scrollIntoView({behavior: 'smooth'});
        }
    }
}
</script>

<style scoped>
.index-title{
    height: 70px;
    width: 100%;
    text-align: center;
    display: flex;
    justify-content: end;
    align-items: center;
    padding: 40px 100px;
    position: fixed;
    z-index: 1000;
    transition: all 1s ease-in-out;
}
.title_scroll {
    background-color: var(--branco);
    justify-content: space-between;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.212);

}
.index-title-buttons{
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 1rem;
}
.logo{
    height: 100px;
    padding-top: 1em;
    padding-bottom: 0.5em;
    padding-left: 0.5em;
    align-items: left;
    display: flex;
    cursor: pointer;
}
.index-title-buttons-solucoes{
    display: block;
}

#contact {
    padding: 10px;
    color: var(--branco);
    background-color: var(--verde);
    transition: all 1s ease-in-out;
    text-shadow: none;
}
#contact:hover {
    transform: scale(1.1);
}

@media only screen and (max-width: 1000px) {
   .index-title {
    padding: 0;
   }

}

@media only screen and (max-width: 700px) {
    .index-title {
        padding: 0;
        justify-content: space-between;
    }
    .logo{
        height: 80px;
    }
    .index-title-buttons {
        flex-direction: column;
        text-align: center;
        height: 100%;
    }
    #contact {
        width: 50%;
    }

}

</style>