<template>
    <div class="index-solutions">
        <div class="index-solutions-title">SOLUÇÕES<h1 class="index-solutions-title-subtitle">DESTAQUES</h1></div>
        <div class="index-solutions-cards">
            <div class="solutions-cards">
                <img src="../../assets/electric-motor.png" alt="motor">
                <h1>Gestão de Ativos</h1>
                <small class="solutions-text">
                    Nossas soluções de gestão de ativos permitem um controle eficaz e otimização do uso de ativos. Isso facilita o acompanhamento do desempenho dos ativos, ajudando na tomada de decisões e maximização da produtividade.
                </small>
            </div>
            <div class="solutions-cards">
                <img src="../../assets/worldwide.png" alt="world">
                <h1>Transformação Digital</h1>
                <small class="solutions-text">
                    Nossas soluções são desenvolvidas com o objetivo de promover a transformação digital nas indústrias, impulsionando a inovação e a competitividade por meio da incorporação de tecnologias avançadas e mudanças culturais. Trabalhamos para conectar pessoas, processos e dados, possibilitando agilidade e eficiência nos seus negócios.
                </small>
            </div>
            <div class="solutions-cards" id="ultimoCard">
                <img src="../../assets/optmization.png" alt="optmization">
                <h1>Otimização de Recursos</h1>
                <small class="solutions-text">
                    Acreditamos que a otimização de recursos é a chave para a eficiência operacional. Nossas soluções ajudam a otimizar o uso de recursos, desde a gestão eficaz de equipamentos até a otimização da mão de obra, o que resulta em redução de custos e aumento da produtividade.
                </small>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    neme: 'IndexSolutions'
}
</script>
<style scoped>
.index-solutions{
    background-color: var(--branco);
    width: 100%;
    height: 100%;
    padding-top: 120px;
}
.index-solutions-title {
    z-index: 1;
    width: 100%;
    position: relative;
    font-weight: 800;
    font-size: 8rem;
    line-height: 150px;
    color: var(--c7);
    text-align: center;
    margin-bottom: 2rem;
}
.index-solutions-title-subtitle {
    font-size: 3rem;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: var(--laranja);
}
.index-solutions-cards {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    background-image:  linear-gradient(rgba(240, 242, 243, 0.562), rgba(234, 237, 245, 0.418)), url('../../assets/fundo-solutions-teste.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    /* filter: opacity(80%);  */
    box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.425);
    height: 70vh;
    width: 100%;
}

.solutions-cards {
    min-height: 80%;
    width: 23%;
    background-color: var(--c6);
    box-shadow: 0px 4px 10px 0.3px rgba(0, 0, 0, 0.25);
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    padding-top: 32px;
    transition: 0.7s ease-in-out;
}
.solutions-cards:hover {
    transform: scale(1.1);
}
.solutions-cards h1 {
    font-size: 1.6rem;
    font-weight: 100;
    margin-bottom: 8px;
}
.solutions-text {
    width: 90%;
    text-align: center;
    font-size: 1.1rem;
    line-height: 1.1;
}
img {
    margin-bottom: 16px;
}

@media only screen and (max-width: 1300px) { 
    .index-solutions {
        height: auto;
        padding-top: 50px;
    }
    .index-solutions-title {
        font-size: 3.9rem;
    }
    .index-solutions-title-subtitle {
        font-size: 2.4rem;
    }
    .index-solutions-cards {
        flex-direction: column;
        justify-content: center;
        height: 100%;
    }

    .solutions-cards{
        display: flex;
        width: 90%;
        margin-top: 20px;
    }

    .solutions-cards:hover {
        transform: scale(1);
    }

    #ultimoCard {
        margin-bottom: 20px;
    }

}
</style>